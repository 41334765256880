/* Position */

.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.fixed {
  position: fixed;
}

.top-0 {
  top: 0;
}
.top-25 {
  top: 25%;
}
.top-50 {
  top: 50%;
}
.top-75 {
  top: 75%;
}
.top-100 {
  top: 100%;
}

.right-0 {
  right: 0;
}
.right-25 {
  right: 25%;
}
.right-50 {
  right: 50%;
}
.right-75 {
  right: 75%;
}
.right-100 {
  right: 100%;
}

.bottom-0 {
  bottom: 0;
}
.bottom-25 {
  bottom: 25%;
}
.bottom-50 {
  bottom: 50%;
}
.bottom-75 {
  bottom: 75%;
}
.bottom-100 {
  bottom: 100%;
}

.left-0 {
  left: 0;
}
.left-25 {
  left: 25%;
}
.left-50 {
  left: 50%;
}
.left-75 {
  left: 75%;
}
.left-100 {
  left: 100%;
}

.z1 {
  z-index: 1;
}
.z2 {
  z-index: 2;
}
.z3 {
  z-index: 3;
}
.z4 {
  z-index: 4;
}
