/* Typography */

:root {
  --line-height-1: 1;
  --line-height-2: 1.125;
  --line-height-3: 1.25;
  --line-height-4: 1.5;
  --line-height-5: 1.75;
  --caps-letter-spacing: 0.2em;
  --bold-font-weight: bold;
  --base-fs: 16px;
  --fs-75: calc(var(--base-fs) * 0.75);
  --fs-1: calc(var(--base-fs) * 1);
  --fs-2: calc(var(--base-fs) * 2);
}

.font-family-inherit {
  font-family: inherit;
}
.font-size-inherit {
  font-size: inherit;
}
.text-decoration-none {
  text-decoration: none;
}

.bold {
  font-weight: var(--bold-font-weight, bold);
}
.regular {
  font-weight: normal;
}
.italic {
  font-style: italic;
}
.caps {
  text-transform: uppercase;
  letter-spacing: var(--caps-letter-spacing);
}

.left-align {
  text-align: left;
}
.center {
  text-align: center;
}
.right-align {
  text-align: right;
}
.justify {
  text-align: justify;
}

.nowrap {
  white-space: nowrap;
}
.break-word {
  word-wrap: break-word;
}

.line-height-1 {
  line-height: var(--line-height-1);
}
.line-height-2 {
  line-height: var(--line-height-2);
}
.line-height-3 {
  line-height: var(--line-height-3);
}
.line-height-4 {
  line-height: var(--line-height-4);
}
.line-height-5 {
  line-height: var(--line-height-5);
}

.list-style-none {
  list-style: none;
}
.underline {
  text-decoration: underline !important;
}

.truncate {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.list-reset {
  list-style: none;
  padding-left: 0;
}

/* Additional */
.fs-75 {
  font-size: var(--fs-75);
}
.fs-1 {
  font-size: var(--fs-1);
}
.fs-2 {
  font-size: var(--fs-2);
}
