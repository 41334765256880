/* Padding */

:root {
  --base: 4px;
  --space-1: calc(var(--base) * 1);
  --space-2: calc(var(--base) * 2);
  --space-3: calc(var(--base) * 3);
  --space-4: calc(var(--base) * 4);
  --space-5: calc(var(--base) * 5);
  --space-6: calc(var(--base) * 6);
  --space-7: calc(var(--base) * 7);
  --space-8: calc(var(--base) * 8);
}

.p-0 {
  padding: 0;
}
.pt-0 {
  padding-top: 0;
}
.pr-0 {
  padding-right: 0;
}
.pb-0 {
  padding-bottom: 0;
}
.pl-0 {
  padding-left: 0;
}
.px-0 {
  padding-left: 0;
  padding-right: 0;
}
.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.p-1 {
  padding: var(--space-1);
}
.pt-1 {
  padding-top: var(--space-1);
}
.pr-1 {
  padding-right: var(--space-1);
}
.pb-1 {
  padding-bottom: var(--space-1);
}
.pl-1 {
  padding-left: var(--space-1);
}
.px-1 {
  padding-left: var(--space-1);
  padding-right: var(--space-1);
}
.py-1 {
  padding-top: var(--space-1);
  padding-bottom: var(--space-1);
}

.p-2 {
  padding: var(--space-2);
}
.pt-2 {
  padding-top: var(--space-2);
}
.pr-2 {
  padding-right: var(--space-2);
}
.pb-2 {
  padding-bottom: var(--space-2);
}
.pl-2 {
  padding-left: var(--space-2);
}
.px-2 {
  padding-left: var(--space-2);
  padding-right: var(--space-2);
}
.py-2 {
  padding-top: var(--space-2);
  padding-bottom: var(--space-2);
}

.p-3 {
  padding: var(--space-3);
}
.pt-3 {
  padding-top: var(--space-3);
}
.pr-3 {
  padding-right: var(--space-3);
}
.pb-3 {
  padding-bottom: var(--space-3);
}
.pl-3 {
  padding-left: var(--space-3);
}
.px-3 {
  padding-left: var(--space-3);
  padding-right: var(--space-3);
}
.py-3 {
  padding-top: var(--space-3);
  padding-bottom: var(--space-3);
}

.p-4 {
  padding: var(--space-4);
}
.pt-4 {
  padding-top: var(--space-4);
}
.pr-4 {
  padding-right: var(--space-4);
}
.pb-4 {
  padding-bottom: var(--space-4);
}
.pl-4 {
  padding-left: var(--space-4);
}
.px-4 {
  padding-left: var(--space-4);
  padding-right: var(--space-4);
}
.py-4 {
  padding-top: var(--space-4);
  padding-bottom: var(--space-4);
}

.p-5 {
  padding: var(--space-5);
}
.pt-5 {
  padding-top: var(--space-5);
}
.pr-5 {
  padding-right: var(--space-5);
}
.pb-5 {
  padding-bottom: var(--space-5);
}
.pl-5 {
  padding-left: var(--space-5);
}
.px-5 {
  padding-left: var(--space-5);
  padding-right: var(--space-5);
}
.py-5 {
  padding-top: var(--space-5);
  padding-bottom: var(--space-5);
}

.p-6 {
  padding: var(--space-6);
}
.pt-6 {
  padding-top: var(--space-6);
}
.pr-6 {
  padding-right: var(--space-6);
}
.pb-6 {
  padding-bottom: var(--space-6);
}
.pl-6 {
  padding-left: var(--space-6);
}
.px-6 {
  padding-left: var(--space-6);
  padding-right: var(--space-6);
}
.py-6 {
  padding-top: var(--space-6);
  padding-bottom: var(--space-6);
}

.p-7 {
  padding: var(--space-7);
}
.pt-7 {
  padding-top: var(--space-7);
}
.pr-7 {
  padding-right: var(--space-7);
}
.pb-7 {
  padding-bottom: var(--space-7);
}
.pl-7 {
  padding-left: var(--space-7);
}
.px-7 {
  padding-left: var(--space-7);
  padding-right: var(--space-7);
}
.py-7 {
  padding-top: var(--space-7);
  padding-bottom: var(--space-7);
}

.p-8 {
  padding: var(--space-8);
}
.pt-8 {
  padding-top: var(--space-8);
}
.pr-8 {
  padding-right: var(--space-8);
}
.pb-8 {
  padding-bottom: var(--space-8);
}
.pl-8 {
  padding-left: var(--space-8);
}
.px-8 {
  padding-left: var(--space-8);
  padding-right: var(--space-8);
}
.py-8 {
  padding-top: var(--space-8);
  padding-bottom: var(--space-8);
}
