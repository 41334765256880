/* Margin */

:root {
  --base: 4px;
  --space-1: calc(var(--base) * 1);
  --space-2: calc(var(--base) * 2);
  --space-3: calc(var(--base) * 3);
  --space-4: calc(var(--base) * 4);
  --space-5: calc(var(--base) * 5);
  --space-6: calc(var(--base) * 6);
  --space-7: calc(var(--base) * 7);
  --space-8: calc(var(--base) * 8);
}

.m-0 {
  margin: 0;
}
.mt-0 {
  margin-top: 0;
}
.mr-0 {
  margin-right: 0;
}
.mb-0 {
  margin-bottom: 0;
}
.ml-0 {
  margin-left: 0;
}
.mx-0 {
  margin-left: 0;
  margin-right: 0;
}
.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.m-1 {
  margin: var(--space-1);
}
.mt-1 {
  margin-top: var(--space-1);
}
.mr-1 {
  margin-right: var(--space-1);
}
.mb-1 {
  margin-bottom: var(--space-1);
}
.ml-1 {
  margin-left: var(--space-1);
}
.mx-1 {
  margin-left: var(--space-1);
  margin-right: var(--space-1);
}
.my-1 {
  margin-top: var(--space-1);
  margin-bottom: var(--space-1);
}

.m-2 {
  margin: var(--space-2);
}
.mt-2 {
  margin-top: var(--space-2);
}
.mr-2 {
  margin-right: var(--space-2);
}
.mb-2 {
  margin-bottom: var(--space-2);
}
.ml-2 {
  margin-left: var(--space-2);
}
.mx-2 {
  margin-left: var(--space-2);
  margin-right: var(--space-2);
}
.my-2 {
  margin-top: var(--space-2);
  margin-bottom: var(--space-2);
}

.m-3 {
  margin: var(--space-3);
}
.mt-3 {
  margin-top: var(--space-3);
}
.mr-3 {
  margin-right: var(--space-3);
}
.mb-3 {
  margin-bottom: var(--space-3);
}
.ml-3 {
  margin-left: var(--space-3);
}
.mx-3 {
  margin-left: var(--space-3);
  margin-right: var(--space-3);
}
.my-3 {
  margin-top: var(--space-3);
  margin-bottom: var(--space-3);
}

.m-4 {
  margin: var(--space-4);
}
.mt-4 {
  margin-top: var(--space-4);
}
.mr-4 {
  margin-right: var(--space-4);
}
.mb-4 {
  margin-bottom: var(--space-4);
}
.ml-4 {
  margin-left: var(--space-4);
}
.mx-4 {
  margin-left: var(--space-4);
  margin-right: var(--space-4);
}
.my-4 {
  margin-top: var(--space-4);
  margin-bottom: var(--space-4);
}

.m-5 {
  margin: var(--space-5);
}
.mt-5 {
  margin-top: var(--space-5);
}
.mr-5 {
  margin-right: var(--space-5);
}
.mb-5 {
  margin-bottom: var(--space-5);
}
.ml-5 {
  margin-left: var(--space-5);
}
.mx-5 {
  margin-left: var(--space-5);
  margin-right: var(--space-5);
}
.my-5 {
  margin-top: var(--space-5);
  margin-bottom: var(--space-5);
}

.m-6 {
  margin: var(--space-6);
}
.mt-6 {
  margin-top: var(--space-6);
}
.mr-6 {
  margin-right: var(--space-6);
}
.mb-6 {
  margin-bottom: var(--space-6);
}
.ml-6 {
  margin-left: var(--space-6);
}
.mx-6 {
  margin-left: var(--space-6);
  margin-right: var(--space-6);
}
.my-6 {
  margin-top: var(--space-6);
  margin-bottom: var(--space-6);
}

.m-7 {
  margin: var(--space-7);
}
.mt-7 {
  margin-top: var(--space-7);
}
.mr-7 {
  margin-right: var(--space-7);
}
.mb-7 {
  margin-bottom: var(--space-7);
}
.ml-7 {
  margin-left: var(--space-7);
}
.mx-7 {
  margin-left: var(--space-7);
  margin-right: var(--space-7);
}
.my-7 {
  margin-top: var(--space-7);
  margin-bottom: var(--space-7);
}

.m-8 {
  margin: var(--space-8);
}
.mt-8 {
  margin-top: var(--space-8);
}
.mr-8 {
  margin-right: var(--space-8);
}
.mb-8 {
  margin-bottom: var(--space-8);
}
.ml-8 {
  margin-left: var(--space-8);
}
.mx-8 {
  margin-left: var(--space-8);
  margin-right: var(--space-8);
}
.my-8 {
  margin-top: var(--space-8);
  margin-bottom: var(--space-8);
}

.mx-n1 {
  margin-left: calc(var(--space-1) * -1);
  margin-right: calc(var(--space-1) * -1);
}
.mx-n2 {
  margin-left: calc(var(--space-2) * -1);
  margin-right: calc(var(--space-2) * -1);
}
.mx-n3 {
  margin-left: calc(var(--space-3) * -1);
  margin-right: calc(var(--space-3) * -1);
}
.mx-n4 {
  margin-left: calc(var(--space-4) * -1);
  margin-right: calc(var(--space-4) * -1);
}
.mx-n5 {
  margin-left: calc(var(--space-5) * -1);
  margin-right: calc(var(--space-5) * -1);
}
.mx-n6 {
  margin-left: calc(var(--space-6) * -1);
  margin-right: calc(var(--space-6) * -1);
}
.mx-n7 {
  margin-left: calc(var(--space-7) * -1);
  margin-right: calc(var(--space-7) * -1);
}
.mx-n8 {
  margin-left: calc(var(--space-8) * -1);
  margin-right: calc(var(--space-8) * -1);
}

.m-auto {
  margin: auto;
}
.mt-auto {
  margin-top: auto;
}
.mr-auto {
  margin-right: auto;
}
.mb-auto {
  margin-bottom: auto;
}
.ml-auto {
  margin-left: auto;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

