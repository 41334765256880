/* Margin */

:root {
  --base: 4px;
  --space-1: calc(var(--base) * 1);
  --space-2: calc(var(--base) * 2);
  --space-3: calc(var(--base) * 3);
  --space-4: calc(var(--base) * 4);
  --space-5: calc(var(--base) * 5);
  --space-6: calc(var(--base) * 6);
  --space-7: calc(var(--base) * 7);
  --space-8: calc(var(--base) * 8);
}

.m-0 {
  margin: 0;
}
.mt-0 {
  margin-top: 0;
}
.mr-0 {
  margin-right: 0;
}
.mb-0 {
  margin-bottom: 0;
}
.ml-0 {
  margin-left: 0;
}
.mx-0 {
  margin-left: 0;
  margin-right: 0;
}
.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.m-1 {
  margin: calc(4px * 1);
  margin: var(--space-1);
}
.mt-1 {
  margin-top: calc(4px * 1);
  margin-top: var(--space-1);
}
.mr-1 {
  margin-right: calc(4px * 1);
  margin-right: var(--space-1);
}
.mb-1 {
  margin-bottom: calc(4px * 1);
  margin-bottom: var(--space-1);
}
.ml-1 {
  margin-left: calc(4px * 1);
  margin-left: var(--space-1);
}
.mx-1 {
  margin-left: calc(4px * 1);
  margin-left: var(--space-1);
  margin-right: calc(4px * 1);
  margin-right: var(--space-1);
}
.my-1 {
  margin-top: calc(4px * 1);
  margin-top: var(--space-1);
  margin-bottom: calc(4px * 1);
  margin-bottom: var(--space-1);
}

.m-2 {
  margin: calc(4px * 2);
  margin: var(--space-2);
}
.mt-2 {
  margin-top: calc(4px * 2);
  margin-top: var(--space-2);
}
.mr-2 {
  margin-right: calc(4px * 2);
  margin-right: var(--space-2);
}
.mb-2 {
  margin-bottom: calc(4px * 2);
  margin-bottom: var(--space-2);
}
.ml-2 {
  margin-left: calc(4px * 2);
  margin-left: var(--space-2);
}
.mx-2 {
  margin-left: calc(4px * 2);
  margin-left: var(--space-2);
  margin-right: calc(4px * 2);
  margin-right: var(--space-2);
}
.my-2 {
  margin-top: calc(4px * 2);
  margin-top: var(--space-2);
  margin-bottom: calc(4px * 2);
  margin-bottom: var(--space-2);
}

.m-3 {
  margin: calc(4px * 3);
  margin: var(--space-3);
}
.mt-3 {
  margin-top: calc(4px * 3);
  margin-top: var(--space-3);
}
.mr-3 {
  margin-right: calc(4px * 3);
  margin-right: var(--space-3);
}
.mb-3 {
  margin-bottom: calc(4px * 3);
  margin-bottom: var(--space-3);
}
.ml-3 {
  margin-left: calc(4px * 3);
  margin-left: var(--space-3);
}
.mx-3 {
  margin-left: calc(4px * 3);
  margin-left: var(--space-3);
  margin-right: calc(4px * 3);
  margin-right: var(--space-3);
}
.my-3 {
  margin-top: calc(4px * 3);
  margin-top: var(--space-3);
  margin-bottom: calc(4px * 3);
  margin-bottom: var(--space-3);
}

.m-4 {
  margin: calc(4px * 4);
  margin: var(--space-4);
}
.mt-4 {
  margin-top: calc(4px * 4);
  margin-top: var(--space-4);
}
.mr-4 {
  margin-right: calc(4px * 4);
  margin-right: var(--space-4);
}
.mb-4 {
  margin-bottom: calc(4px * 4);
  margin-bottom: var(--space-4);
}
.ml-4 {
  margin-left: calc(4px * 4);
  margin-left: var(--space-4);
}
.mx-4 {
  margin-left: calc(4px * 4);
  margin-left: var(--space-4);
  margin-right: calc(4px * 4);
  margin-right: var(--space-4);
}
.my-4 {
  margin-top: calc(4px * 4);
  margin-top: var(--space-4);
  margin-bottom: calc(4px * 4);
  margin-bottom: var(--space-4);
}

.m-5 {
  margin: calc(4px * 5);
  margin: var(--space-5);
}
.mt-5 {
  margin-top: calc(4px * 5);
  margin-top: var(--space-5);
}
.mr-5 {
  margin-right: calc(4px * 5);
  margin-right: var(--space-5);
}
.mb-5 {
  margin-bottom: calc(4px * 5);
  margin-bottom: var(--space-5);
}
.ml-5 {
  margin-left: calc(4px * 5);
  margin-left: var(--space-5);
}
.mx-5 {
  margin-left: calc(4px * 5);
  margin-left: var(--space-5);
  margin-right: calc(4px * 5);
  margin-right: var(--space-5);
}
.my-5 {
  margin-top: calc(4px * 5);
  margin-top: var(--space-5);
  margin-bottom: calc(4px * 5);
  margin-bottom: var(--space-5);
}

.m-6 {
  margin: calc(4px * 6);
  margin: var(--space-6);
}
.mt-6 {
  margin-top: calc(4px * 6);
  margin-top: var(--space-6);
}
.mr-6 {
  margin-right: calc(4px * 6);
  margin-right: var(--space-6);
}
.mb-6 {
  margin-bottom: calc(4px * 6);
  margin-bottom: var(--space-6);
}
.ml-6 {
  margin-left: calc(4px * 6);
  margin-left: var(--space-6);
}
.mx-6 {
  margin-left: calc(4px * 6);
  margin-left: var(--space-6);
  margin-right: calc(4px * 6);
  margin-right: var(--space-6);
}
.my-6 {
  margin-top: calc(4px * 6);
  margin-top: var(--space-6);
  margin-bottom: calc(4px * 6);
  margin-bottom: var(--space-6);
}

.m-7 {
  margin: calc(4px * 7);
  margin: var(--space-7);
}
.mt-7 {
  margin-top: calc(4px * 7);
  margin-top: var(--space-7);
}
.mr-7 {
  margin-right: calc(4px * 7);
  margin-right: var(--space-7);
}
.mb-7 {
  margin-bottom: calc(4px * 7);
  margin-bottom: var(--space-7);
}
.ml-7 {
  margin-left: calc(4px * 7);
  margin-left: var(--space-7);
}
.mx-7 {
  margin-left: calc(4px * 7);
  margin-left: var(--space-7);
  margin-right: calc(4px * 7);
  margin-right: var(--space-7);
}
.my-7 {
  margin-top: calc(4px * 7);
  margin-top: var(--space-7);
  margin-bottom: calc(4px * 7);
  margin-bottom: var(--space-7);
}

.m-8 {
  margin: calc(4px * 8);
  margin: var(--space-8);
}
.mt-8 {
  margin-top: calc(4px * 8);
  margin-top: var(--space-8);
}
.mr-8 {
  margin-right: calc(4px * 8);
  margin-right: var(--space-8);
}
.mb-8 {
  margin-bottom: calc(4px * 8);
  margin-bottom: var(--space-8);
}
.ml-8 {
  margin-left: calc(4px * 8);
  margin-left: var(--space-8);
}
.mx-8 {
  margin-left: calc(4px * 8);
  margin-left: var(--space-8);
  margin-right: calc(4px * 8);
  margin-right: var(--space-8);
}
.my-8 {
  margin-top: calc(4px * 8);
  margin-top: var(--space-8);
  margin-bottom: calc(4px * 8);
  margin-bottom: var(--space-8);
}

.mx-n1 {
  margin-left: calc(calc(4px * 1) * -1);
  margin-left: calc(var(--space-1) * -1);
  margin-right: calc(calc(4px * 1) * -1);
  margin-right: calc(var(--space-1) * -1);
}
.mx-n2 {
  margin-left: calc(calc(4px * 2) * -1);
  margin-left: calc(var(--space-2) * -1);
  margin-right: calc(calc(4px * 2) * -1);
  margin-right: calc(var(--space-2) * -1);
}
.mx-n3 {
  margin-left: calc(calc(4px * 3) * -1);
  margin-left: calc(var(--space-3) * -1);
  margin-right: calc(calc(4px * 3) * -1);
  margin-right: calc(var(--space-3) * -1);
}
.mx-n4 {
  margin-left: calc(calc(4px * 4) * -1);
  margin-left: calc(var(--space-4) * -1);
  margin-right: calc(calc(4px * 4) * -1);
  margin-right: calc(var(--space-4) * -1);
}
.mx-n5 {
  margin-left: calc(calc(4px * 5) * -1);
  margin-left: calc(var(--space-5) * -1);
  margin-right: calc(calc(4px * 5) * -1);
  margin-right: calc(var(--space-5) * -1);
}
.mx-n6 {
  margin-left: calc(calc(4px * 6) * -1);
  margin-left: calc(var(--space-6) * -1);
  margin-right: calc(calc(4px * 6) * -1);
  margin-right: calc(var(--space-6) * -1);
}
.mx-n7 {
  margin-left: calc(calc(4px * 7) * -1);
  margin-left: calc(var(--space-7) * -1);
  margin-right: calc(calc(4px * 7) * -1);
  margin-right: calc(var(--space-7) * -1);
}
.mx-n8 {
  margin-left: calc(calc(4px * 8) * -1);
  margin-left: calc(var(--space-8) * -1);
  margin-right: calc(calc(4px * 8) * -1);
  margin-right: calc(var(--space-8) * -1);
}

.m-auto {
  margin: auto;
}
.mt-auto {
  margin-top: auto;
}
.mr-auto {
  margin-right: auto;
}
.mb-auto {
  margin-bottom: auto;
}
.ml-auto {
  margin-left: auto;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}


/* Padding */

:root {
  --base: 4px;
  --space-1: calc(var(--base) * 1);
  --space-2: calc(var(--base) * 2);
  --space-3: calc(var(--base) * 3);
  --space-4: calc(var(--base) * 4);
  --space-5: calc(var(--base) * 5);
  --space-6: calc(var(--base) * 6);
  --space-7: calc(var(--base) * 7);
  --space-8: calc(var(--base) * 8);
}

.p-0 {
  padding: 0;
}
.pt-0 {
  padding-top: 0;
}
.pr-0 {
  padding-right: 0;
}
.pb-0 {
  padding-bottom: 0;
}
.pl-0 {
  padding-left: 0;
}
.px-0 {
  padding-left: 0;
  padding-right: 0;
}
.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.p-1 {
  padding: calc(4px * 1);
  padding: var(--space-1);
}
.pt-1 {
  padding-top: calc(4px * 1);
  padding-top: var(--space-1);
}
.pr-1 {
  padding-right: calc(4px * 1);
  padding-right: var(--space-1);
}
.pb-1 {
  padding-bottom: calc(4px * 1);
  padding-bottom: var(--space-1);
}
.pl-1 {
  padding-left: calc(4px * 1);
  padding-left: var(--space-1);
}
.px-1 {
  padding-left: calc(4px * 1);
  padding-left: var(--space-1);
  padding-right: calc(4px * 1);
  padding-right: var(--space-1);
}
.py-1 {
  padding-top: calc(4px * 1);
  padding-top: var(--space-1);
  padding-bottom: calc(4px * 1);
  padding-bottom: var(--space-1);
}

.p-2 {
  padding: calc(4px * 2);
  padding: var(--space-2);
}
.pt-2 {
  padding-top: calc(4px * 2);
  padding-top: var(--space-2);
}
.pr-2 {
  padding-right: calc(4px * 2);
  padding-right: var(--space-2);
}
.pb-2 {
  padding-bottom: calc(4px * 2);
  padding-bottom: var(--space-2);
}
.pl-2 {
  padding-left: calc(4px * 2);
  padding-left: var(--space-2);
}
.px-2 {
  padding-left: calc(4px * 2);
  padding-left: var(--space-2);
  padding-right: calc(4px * 2);
  padding-right: var(--space-2);
}
.py-2 {
  padding-top: calc(4px * 2);
  padding-top: var(--space-2);
  padding-bottom: calc(4px * 2);
  padding-bottom: var(--space-2);
}

.p-3 {
  padding: calc(4px * 3);
  padding: var(--space-3);
}
.pt-3 {
  padding-top: calc(4px * 3);
  padding-top: var(--space-3);
}
.pr-3 {
  padding-right: calc(4px * 3);
  padding-right: var(--space-3);
}
.pb-3 {
  padding-bottom: calc(4px * 3);
  padding-bottom: var(--space-3);
}
.pl-3 {
  padding-left: calc(4px * 3);
  padding-left: var(--space-3);
}
.px-3 {
  padding-left: calc(4px * 3);
  padding-left: var(--space-3);
  padding-right: calc(4px * 3);
  padding-right: var(--space-3);
}
.py-3 {
  padding-top: calc(4px * 3);
  padding-top: var(--space-3);
  padding-bottom: calc(4px * 3);
  padding-bottom: var(--space-3);
}

.p-4 {
  padding: calc(4px * 4);
  padding: var(--space-4);
}
.pt-4 {
  padding-top: calc(4px * 4);
  padding-top: var(--space-4);
}
.pr-4 {
  padding-right: calc(4px * 4);
  padding-right: var(--space-4);
}
.pb-4 {
  padding-bottom: calc(4px * 4);
  padding-bottom: var(--space-4);
}
.pl-4 {
  padding-left: calc(4px * 4);
  padding-left: var(--space-4);
}
.px-4 {
  padding-left: calc(4px * 4);
  padding-left: var(--space-4);
  padding-right: calc(4px * 4);
  padding-right: var(--space-4);
}
.py-4 {
  padding-top: calc(4px * 4);
  padding-top: var(--space-4);
  padding-bottom: calc(4px * 4);
  padding-bottom: var(--space-4);
}

.p-5 {
  padding: calc(4px * 5);
  padding: var(--space-5);
}
.pt-5 {
  padding-top: calc(4px * 5);
  padding-top: var(--space-5);
}
.pr-5 {
  padding-right: calc(4px * 5);
  padding-right: var(--space-5);
}
.pb-5 {
  padding-bottom: calc(4px * 5);
  padding-bottom: var(--space-5);
}
.pl-5 {
  padding-left: calc(4px * 5);
  padding-left: var(--space-5);
}
.px-5 {
  padding-left: calc(4px * 5);
  padding-left: var(--space-5);
  padding-right: calc(4px * 5);
  padding-right: var(--space-5);
}
.py-5 {
  padding-top: calc(4px * 5);
  padding-top: var(--space-5);
  padding-bottom: calc(4px * 5);
  padding-bottom: var(--space-5);
}

.p-6 {
  padding: calc(4px * 6);
  padding: var(--space-6);
}
.pt-6 {
  padding-top: calc(4px * 6);
  padding-top: var(--space-6);
}
.pr-6 {
  padding-right: calc(4px * 6);
  padding-right: var(--space-6);
}
.pb-6 {
  padding-bottom: calc(4px * 6);
  padding-bottom: var(--space-6);
}
.pl-6 {
  padding-left: calc(4px * 6);
  padding-left: var(--space-6);
}
.px-6 {
  padding-left: calc(4px * 6);
  padding-left: var(--space-6);
  padding-right: calc(4px * 6);
  padding-right: var(--space-6);
}
.py-6 {
  padding-top: calc(4px * 6);
  padding-top: var(--space-6);
  padding-bottom: calc(4px * 6);
  padding-bottom: var(--space-6);
}

.p-7 {
  padding: calc(4px * 7);
  padding: var(--space-7);
}
.pt-7 {
  padding-top: calc(4px * 7);
  padding-top: var(--space-7);
}
.pr-7 {
  padding-right: calc(4px * 7);
  padding-right: var(--space-7);
}
.pb-7 {
  padding-bottom: calc(4px * 7);
  padding-bottom: var(--space-7);
}
.pl-7 {
  padding-left: calc(4px * 7);
  padding-left: var(--space-7);
}
.px-7 {
  padding-left: calc(4px * 7);
  padding-left: var(--space-7);
  padding-right: calc(4px * 7);
  padding-right: var(--space-7);
}
.py-7 {
  padding-top: calc(4px * 7);
  padding-top: var(--space-7);
  padding-bottom: calc(4px * 7);
  padding-bottom: var(--space-7);
}

.p-8 {
  padding: calc(4px * 8);
  padding: var(--space-8);
}
.pt-8 {
  padding-top: calc(4px * 8);
  padding-top: var(--space-8);
}
.pr-8 {
  padding-right: calc(4px * 8);
  padding-right: var(--space-8);
}
.pb-8 {
  padding-bottom: calc(4px * 8);
  padding-bottom: var(--space-8);
}
.pl-8 {
  padding-left: calc(4px * 8);
  padding-left: var(--space-8);
}
.px-8 {
  padding-left: calc(4px * 8);
  padding-left: var(--space-8);
  padding-right: calc(4px * 8);
  padding-right: var(--space-8);
}
.py-8 {
  padding-top: calc(4px * 8);
  padding-top: var(--space-8);
  padding-bottom: calc(4px * 8);
  padding-bottom: var(--space-8);
}

/* Typography */

:root {
  --line-height-1: 1;
  --line-height-2: 1.125;
  --line-height-3: 1.25;
  --line-height-4: 1.5;
  --line-height-5: 1.75;
  --caps-letter-spacing: 0.2em;
  --bold-font-weight: bold;
  --base-fs: 16px;
  --fs-75: calc(var(--base-fs) * 0.75);
  --fs-1: calc(var(--base-fs) * 1);
  --fs-2: calc(var(--base-fs) * 2);
}

.font-family-inherit {
  font-family: inherit;
}
.font-size-inherit {
  font-size: inherit;
}
.text-decoration-none {
  text-decoration: none;
}

.bold {
  font-weight: bold;
  font-weight: var(--bold-font-weight, bold);
}
.regular {
  font-weight: normal;
}
.italic {
  font-style: italic;
}
.caps {
  text-transform: uppercase;
  letter-spacing: 0.2em;
  letter-spacing: var(--caps-letter-spacing);
}

.left-align {
  text-align: left;
}
.center {
  text-align: center;
}
.right-align {
  text-align: right;
}
.justify {
  text-align: justify;
}

.nowrap {
  white-space: nowrap;
}
.break-word {
  word-wrap: break-word;
}

.line-height-1 {
  line-height: 1;
  line-height: var(--line-height-1);
}
.line-height-2 {
  line-height: 1.125;
  line-height: var(--line-height-2);
}
.line-height-3 {
  line-height: 1.25;
  line-height: var(--line-height-3);
}
.line-height-4 {
  line-height: 1.5;
  line-height: var(--line-height-4);
}
.line-height-5 {
  line-height: 1.75;
  line-height: var(--line-height-5);
}

.list-style-none {
  list-style: none;
}
.underline {
  text-decoration: underline !important;
}

.truncate {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.list-reset {
  list-style: none;
  padding-left: 0;
}

/* Additional */
.fs-75 {
  font-size: calc(16px * 0.75);
  font-size: var(--fs-75);
}
.fs-1 {
  font-size: calc(16px * 1);
  font-size: var(--fs-1);
}
.fs-2 {
  font-size: calc(16px * 2);
  font-size: var(--fs-2);
}

/* Layout */

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.h-25 {
  height: 25%;
}

.h-50 {
  height: 50%;
}

.h-75 {
  height: 75%;
}

.h-100 {
  height: 100%;
}

.vh-25 {
  min-height: 25vh;
}

.vh-50 {
  min-height: 50vh;
}

.vh-75 {
  min-height: 75vh;
}

.vh-100 {
  min-height: 100vh;
}
.mw-sm {
  max-width: 576px;
}
.mw-md {
  max-width: 768px;
}
.mw-lg {
  max-width: 992px;
}
.mw-xl {
  max-width: 1200px;
}
.mw-100 {
  max-width: 100%;
}
/* Color */

:root {
  --white: #ffffff;
}

.bg-white {
  background-color: #ffffff;
  background-color: var(--white);
}

/* Flex */

.flex {
  display: -webkit-flex;
  display: flex;
}

.flex-column {
  -webkit-flex-direction: column;
          flex-direction: column;
}
.flex-wrap {
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.items-start {
  -webkit-align-items: flex-start;
          align-items: flex-start;
}
.items-end {
  -webkit-align-items: flex-end;
          align-items: flex-end;
}
.items-center {
  -webkit-align-items: center;
          align-items: center;
}
.items-baseline {
  -webkit-align-items: baseline;
          align-items: baseline;
}
.items-stretch {
  -webkit-align-items: stretch;
          align-items: stretch;
}

.self-start {
  -webkit-align-self: flex-start;
          align-self: flex-start;
}
.self-end {
  -webkit-align-self: flex-end;
          align-self: flex-end;
}
.self-center {
  -webkit-align-self: center;
          align-self: center;
}
.self-baseline {
  -webkit-align-self: baseline;
          align-self: baseline;
}
.self-stretch {
  -webkit-align-self: stretch;
          align-self: stretch;
}

.justify-start {
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}
.justify-end {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}
.justify-center {
  -webkit-justify-content: center;
          justify-content: center;
}
.justify-between {
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.justify-around {
  -webkit-justify-content: space-around;
          justify-content: space-around;
}
.justify-evenly {
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.content-start {
  -webkit-align-content: flex-start;
          align-content: flex-start;
}
.content-end {
  -webkit-align-content: flex-end;
          align-content: flex-end;
}
.content-center {
  -webkit-align-content: center;
          align-content: center;
}
.content-between {
  -webkit-align-content: space-between;
          align-content: space-between;
}
.content-around {
  -webkit-align-content: space-around;
          align-content: space-around;
}
.content-stretch {
  -webkit-align-content: stretch;
          align-content: stretch;
}

/* Position */

.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.fixed {
  position: fixed;
}

.top-0 {
  top: 0;
}
.top-25 {
  top: 25%;
}
.top-50 {
  top: 50%;
}
.top-75 {
  top: 75%;
}
.top-100 {
  top: 100%;
}

.right-0 {
  right: 0;
}
.right-25 {
  right: 25%;
}
.right-50 {
  right: 50%;
}
.right-75 {
  right: 75%;
}
.right-100 {
  right: 100%;
}

.bottom-0 {
  bottom: 0;
}
.bottom-25 {
  bottom: 25%;
}
.bottom-50 {
  bottom: 50%;
}
.bottom-75 {
  bottom: 75%;
}
.bottom-100 {
  bottom: 100%;
}

.left-0 {
  left: 0;
}
.left-25 {
  left: 25%;
}
.left-50 {
  left: 50%;
}
.left-75 {
  left: 75%;
}
.left-100 {
  left: 100%;
}

.z1 {
  z-index: 1;
}
.z2 {
  z-index: 2;
}
.z3 {
  z-index: 3;
}
.z4 {
  z-index: 4;
}



/* Global styles for html elements and antd components */

.ant-table {
  overflow-x: auto;
}

.header {
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}

