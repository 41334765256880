/* Layout */

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.h-25 {
  height: 25%;
}

.h-50 {
  height: 50%;
}

.h-75 {
  height: 75%;
}

.h-100 {
  height: 100%;
}

.vh-25 {
  min-height: 25vh;
}

.vh-50 {
  min-height: 50vh;
}

.vh-75 {
  min-height: 75vh;
}

.vh-100 {
  min-height: 100vh;
}
.mw-sm {
  max-width: 576px;
}
.mw-md {
  max-width: 768px;
}
.mw-lg {
  max-width: 992px;
}
.mw-xl {
  max-width: 1200px;
}
.mw-100 {
  max-width: 100%;
}